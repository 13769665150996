import React, { useState, useEffect, useRef } from 'react';
import axios from './../../axios';
import { useScreenContext } from './../../Contexts/ScreenContext';
import { useDataContext } from '../../Contexts/DataContext';
import AudioDeviceManagerComponent from '../Modules/AudioDeviceManager/AudioDeviceManagerComponent';
import GrantPermissionsComponent from '../Modules/GrantPermissions/GrantPermissionsComponent';
import { images } from '../../Constants/index';
import { VoiceRecorder } from '../Modules/VoiceRecorderComponent';
import LoaderModule from '../Modules/LoaderModule';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'lottie-react';

import './Start.css';
import { audioDeviceManager } from '../../Services/AudioDeviceManager';

const Start = ({setAvatarActive, setAudioTalkActive}) => {
    const [contentLoaded, setContentLoaded] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 6;
    const fadeOutAnimationTime = 0.4;

    const grantPermissionsRef = useRef(null);
    
    const { setCurrentScreen, setError } = useScreenContext();
    const { surveyData, setSurveyData, setUserData, systemPromptData, setSystemPromptData, promptData, setPromptData, setQuestionsData, setExternalInfo, setSavedAIPhrases, offlineAppPhrases } = useDataContext();

    let surveyId = 1; // TEMP

    const tutorialData = [
        {step: 1, text: "Postaraj się przeprowadzić ankietę w cichym miejscu. Mów wyraźnie, nie za szybko i raczej zwięźle. Masz ok. 7 minut, a w razie potrzeby jeszcze trochę czasu rezerwowego.", img: images.tutorial1},
        {step: 2, text: "Gdy bot mówi, to Cię nie słyszy. Mów wtedy, gdy zielony mikrofon miga i jest aktywny.", img: images.tutorial2},
        {step: 3, text: "Jak odpowiadać na pytania? Wybieraj odpowiedzi na ekranie (palcem lub myszką). Nie można odpowiadać na pytania ustnie.", img: images.tutorial3},
        {step: 4, text: "Nasze wdrożenie opiera się na pionierskich rozwiązaniach. Jeśli zauważysz jakiekolwiek nieprawidłowości w działaniu, pisz na adres <em>sayhello@stettiner.eu</em>. Dziękujemy :)", img: images.tutorial4},
    ]

    useEffect(() => {
        axios.get('/getStartData', {
            params: {
                surveyId: surveyId
            },

        }).then(async(response) => {

            if(response.data.status === "ok") {
                if(response.data.tokenBalance <= response.data.tokenBalanceTreshold) {
                    setError(false, "Not enough Heygen tokens.");
                    return;
                }
                
                setSurveyData(response.data.surveyData);
                setUserData({userId: 1});

                setPromptData(response.data.prompts);
                setSystemPromptData(response.data.systemPrompt[0]);
                setExternalInfo(response.data.externalInfo);

                console.log("Prompts loaded", response.data.prompts);
                console.log("System loaded", response.data.systemPrompt);
                console.log("External info loaded", response.data.externalInfo);

                const questions = response.data.questions
                const groupedQuestions = questions.reduce((acc, question) => {
                    if(!acc[question.type]) {
                        acc[question.type] = [];
                    }
                    acc[question.type].push(question);
                    return acc;
                }, {})
        
                setQuestionsData(groupedQuestions);
                setSavedAIPhrases(response.data.phrases);
                console.log("Questions loaded", response.data.questions);

                setContentLoaded(true);

            } else {
                if(response.data.status === "bad") {
                    setError(false, "Error while getting start data");
                }
                if(response.data.status === 'offline') {
                    setError(true, offlineAppPhrases[Math.floor(Math.random() * offlineAppPhrases.length)]);
                }
            }
        })

        loadImages([images.logotype, images.background, images.microphoneOff, images.tutorial1, images.tutorial2, images.tutorial3, images.tutorial4]).then(() => {
            setImagesLoaded(true);
        });

    }, []);

    const loadImages = async(imageSources) => {
        return new Promise((resolve, reject) => {
            let loadedCount = 0;
            const totalImages = imageSources.length;

            if(totalImages === 0) {
                resolve();
            }

            imageSources.forEach((src) => {
                const img = new Image();
                img.src = src;
                img.onload = () => {
                    loadedCount++;
                    if(loadedCount === totalImages) {
                        resolve();
                    }
                }

                img.onerror = (err) => {
                    console.error(`Error loading image: ${src}`);
                    reject(err);
                }
            })
        });
    }

    const handleGoToIntro = async() => {
        setCurrentPage(1)

        setTimeout(() => {
            setCurrentScreen("survey");
        }, fadeOutAnimationTime * 1000)
    };

    const changeAvatarState = (e) => {
        setAvatarActive(e);
    }

    const changeAudioState = (e) => {
        setAudioTalkActive(e);
    }

    const goToNextPage = async() => {
        navigator.permissions.query({name: 'microphone'}).then((result) => {
            if(result.state === "granted") {
                if(currentPage == totalPages) {
                    handleGoToIntro();
                } else {
                    setCurrentPage((prev) => prev + 1);
                }
                

            } else if(result.state === "denied") {
                setError(true, "Przeglądarka zablokowała uprawnienia do mikrofonu. Niestety są one niezbędne do działania aplikacji. Wejdź w ustawienia przeglądarki -> Prywatność i Bezpieczeństwo, a następnie przyznaj uprawnienia do mikrofonu dla tego adresu.");
            
            } else {
                grantPermissionsRef.current.open();
            }
        });
    }

    const prevButtonVariants = {
        active: { width: 128, opacity: 1 },
        disabled: { width: 0, opacity: 0 },
    }

    return (
        <div className='start__container'>
            {contentLoaded && imagesLoaded ? (
                <React.Fragment>
                    <AnimatePresence>
                            <motion.div className='start'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.4 } }}
                                exit={{ opacity: 0, transition: { duration: fadeOutAnimationTime } }}
                            >
                                <div className='start_logo-area'>
                                    <img className='start__logo-image' src={images.logotype} alt="SAA LOGO"/>
                                </div>
                                <AnimatePresence mode='wait'>
                                    {currentPage == 1 && (
                                        <motion.div
                                            key={`page-${currentPage}`}  
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: [0, 0, 1], transition: { duration: 1.2 } }}
                                            exit={{ opacity: 0, transition: { duration: fadeOutAnimationTime } }}  
                                            className='start__content'
                                        >
                                            <h1>{surveyData.name}</h1>
                                            <div className='start__paragraph'>
                                                <p>Za chwilę porozmawiasz z wirtualnym asystentem Urzędu Miasta Szczecin - pierwszym w Polsce botem przeprowadzającym ankietę dla władz miasta.</p>
                                                <p>Chcemy poznać zdanie mieszkańców, aby nowa strategia "{surveyData.name}" odzwierciedlała ich realne potrzeby i oczekiwania, a miasto było przyjaznym miejscem do życia.</p>
                                                <p>Twoje dane są bezpieczne i podlegają przepisom RODO.</p>
                                            </div>
                                            <div className='start__button-strategy-container'>
                                                <button className='start__button-strategy'><h3>{surveyData.name}<br/>Pobierz Dokument.</h3></button>
                                            </div>
                                            <h3>Koniecznie włącz głośniki i mikrofon.<br/> Zaczynamy :)</h3>
                                        </motion.div>
                                    )}  
                                    {currentPage == 2 && (
                                        <motion.div 
                                            key={`page-${currentPage}`} 
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: [0, 0, 1], transition: { duration: 1.2 } }}
                                            exit={{ opacity: 0, transition: { duration: fadeOutAnimationTime } }} 
                                            className='start__microphone-content'
                                        >
                                            <div className='start__microphone'>
                                                <div className='start__microphone-icon'>
                                                    <Lottie animationData={images.microphoneOn} loop={true} autoplay={true} style={{width: 56, height: 56}} />
                                                </div>
                                                <h3>Jeżeli ta ikona jest aktywna, to możesz mówić.</h3>
                                            </div>
                                            <div className='start__microphone'>
                                                <div className='start__microphone-icon'>
                                                    <img src={images.microphoneOff} />
                                                </div>
                                                <h3>Jeżeli ikona jest nieaktywna, nasz bot Ciebie nie słyszy.</h3>
                                            </div>
                                            <AudioDeviceManagerComponent></AudioDeviceManagerComponent>
                                        </motion.div>
                                    )}   
                                    {currentPage >= 3 && (
                                        <motion.div 
                                            key={`page-${currentPage}`} 
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: [0, 0, 1], transition: { duration: 1.2 } }}
                                            exit={{ opacity: 0, transition: { duration: fadeOutAnimationTime } }} 
                                            className='start__content-tutorial'
                                        >
                                            <div className='start__tutorial-img'>
                                                <img src={tutorialData[currentPage - 3].img} />
                                            </div>
                                            <div className='start__tutorial-text'>
                                                <h3>{tutorialData[currentPage - 3].step}.</h3>
                                                <h3 dangerouslySetInnerHTML={{__html: tutorialData[currentPage - 3].text}}/>
                                            </div>
                                        </motion.div>
                                    )}             
                                </AnimatePresence>                         
                                <div className='start__bottom-content'>
                                    <img className='start__background-image' src={images.background} alt="BACKGROUND"/>
                                    <div className='start__buttons-container'>
                                        <motion.button disabled={currentPage == 1} onClick={() => setCurrentPage((prev) => prev - 1)} className='start__prev-button' variants={prevButtonVariants} animate={currentPage == 1 ? "disabled" : "active"}><img src={images.arrowLeft}/></motion.button>
                                        <button className='start__button' onClick={() => goToNextPage()}><h3>{currentPage != totalPages ? `DALEJ \xa0\xa0 ${currentPage} / ${totalPages}` : 'ROZPOCZNIJ ANKIETĘ'}</h3></button>
                                    </div>
                                </div>
                            </motion.div>
                            </AnimatePresence>
                            
                            <div className='start__temp'>
                                <label>Is Avatar Active?</label>
                                <input type='checkbox' onChange={(e) => changeAvatarState(e.target.checked)} /><br/><br/>
                                <label>Is Audio Talk Active?</label>
                                <input type='checkbox' onChange={(e) => changeAudioState(e.target.checked)} /><br/><br/>
                            </div>
                    <GrantPermissionsComponent ref={grantPermissionsRef} nextPage={goToNextPage} />
                </React.Fragment>
            ) : (
                <LoaderModule type={'default'} />
            )}
        </div>
    )
}

export default Start;